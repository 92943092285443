<template>
  <div>
    <hr>
    <b-spinner
        v-if="spinner"
        variant="primary"
        class="spinners"
    />
    <b-row class="mt-1">
      <b-col lg="7" md="12" sm="12" cols="12">
        <b-card-actions
            class="cari-liste-card menuList"
        >
          <b-row class="mt-50 mb-3">
            <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
              <b-col cols="12">
                <h4 class="text-white d-inline-block">Menü Listesi</h4>
              </b-col>
            </div>
          </b-row>
          <hr class="myHr">
          <table class="table table-striped table-hover mt-1">
            <thead>
            <tr>
              <th
                  width="1%"
                  scope="col"
                  :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"
              />
              <th
                  width="20%"
                  scope="col"
                  :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"
              >
                MENÜ ADI
              </th>
              <th
                  width="25%"
                  scope="col"
                  :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"
              >
                MENÜ LİNKİ
              </th>
              <th
                  width="20%"
                  scope="col"
                  :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"
              >
                MENÜ TÜRÜ
              </th>
              <th
                  width="9%"
                  scope="col"
                  :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"
              >
                SIRA
              </th>
              <th
                  width="15%"
                  scope="col"
                  :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"
              />
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(item, index) in salaryListItems"
                :key="index"
            >
              <td class="bold pl-1">
                {{ item.sira }}
              </td>
              <td>
                {{ item.yazi }}
              </td>
              <td>
                {{ item.link }}
              </td>
              <td>
                {{ item.menuTuru == 0 ? 'Standart Menü' : 'Açılır Menü' }}
              </td>
              <td>
                <b-input-group>
                  <b-form-input
                      :ref="'input' + item.menuID"
                      :value="item.sira"
                      size="sm"
                      @change="siraUpdate(item.menuID)"
                      autocomplete="off"
                      class="min-w-50"
                  />
                </b-input-group>
              </td>
              <td class="text-right">
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="bgInfo"
                    class="btn-icon rounded-circle mr-1"
                    size="sm"
                    :to="'/altMenu/' + item.menuID"
                >
                  <feather-icon icon="ListIcon"/>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="bgDanger"
                    class="btn-icon rounded-circle mr-1"
                    size="sm"
                    @click="menuSil(item.menuID)"
                    :style="{backgroundColor:$store.getters.bgDanger,color:'white'}"
                >
                  <feather-icon icon="TrashIcon"/>
                </b-button>
              </td>
            </tr>
            </tbody>
          </table>
        </b-card-actions>
      </b-col>
      <b-col lg="5" md="12" sm="12" cols="12">
        <b-card-actions
            class="cari-liste-card"
        >
          <b-row class="mt-50 mb-3">
            <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
              <b-col cols="12">
                <h4 class="text-white d-inline-block">Ana Menü Kaydı</h4>
              </b-col>
            </div>
          </b-row>
          <hr class="myHr">
          <b-row class="mt-1">
            <b-col cols="12">
              <b-form-group
                  label="Menü Adı"
                  label-for="basicInput"
              >
                <b-form-input
                    id="basicInput"
                    v-model="menuAdi"
                    placeholder="Menü Adı"
                    autocomplete="off"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Menü Türü">
                <v-select
                    v-model="menuTuru"
                    :components="{ OpenIndicator, Deselect }"
                    label="title"
                    :options="menuTuruOptions"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Hedef">
                <v-select
                    v-model="hedef"
                    :components="{ OpenIndicator, Deselect }"
                    label="title"
                    :options="hedefOptions"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Site Linkleri">
                <v-select
                    v-model="siteLink"
                    :components="{ OpenIndicator, Deselect }"
                    label="baslik"
                    :options="siteLinks"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Sıralama"
                  label-for="basicInput"
              >
                <b-form-input
                    id="basicInput"
                    v-model="siralama"
                    autocomplete="off"
                    placeholder="Menü Sırası"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="2">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgInfo"
                  class="mt-1 mr-1 border-0"
                  :style="{backgroundColor:$store.getters.bgSuccess}"
                  @click="menuSave"
              >
                Kaydet
              </b-button>
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import _ from 'lodash'
import axios from 'axios'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import {
  BProgress, BSpinner,
  BRow,
  BCol,
  BCard,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormCheckboxGroup,
  BTable,
  BModal,
  BImg,
  BInputGroup,
  BContainer,
  BFormInput,
  BPagination,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormFile,
} from 'bootstrap-vue'

export default {
  components: {
    BCardActions,
    BProgress,
    BButton,
    BContainer,
    BAvatar,
    BSpinner,
    BCard,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BImg,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BFormCheckboxGroup,
    BTable,
    BModal,
    BInputGroup,
    BFormFile,
    vSelect,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      spinner: true,
      Deselect: {
        render: createElement => createElement('span', ''),
      },
      OpenIndicator: {
        render: createElement => createElement('span', ''),
      },
      menuAdi: '',
      siralama: '',
      siteLink: {
        baslik: 'Ana Sayfa',
        link: './'
      },
      siteLinks: [
        {
          baslik: 'Ana Sayfa',
          link: './'
        },
        {
          baslik: 'Açılır Menü',
          link: ''
        },
      ],
      menuTuru: {
        title: 'Standart Menü',
        key: 0
      },
      menuTuruOptions: [
        {
          title: 'Standart Menü',
          key: 0
        },
        {
          title: 'Açılır menü',
          key: 1
        },
      ],
      hedef: {
        title: 'Aynı Pencere',
        key: '_parent'
      },
      hedefOptions: [
        {
          title: 'Aynı Pencere',
          key: '_parent'
        },
        {
          title: 'Yeni Pencere',
          key: '_blank'
        },
      ],
    }
  },
  computed: {
    salaryListItems() {
      return _.orderBy(this.$store.getters.menuReturn.liste, 'sira', 'asc')
    },
  },
  mounted() {
  },
  created() {
    this.$store
        .dispatch('menu')
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          this.spinner = false
          res.linkListesi.forEach(element => {
            this.siteLinks.push({
              baslik: element.baslik,
              link: element.link
            })
          })
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  beforeDestroy() {
    this.$store.commit('clearMenu')
  },
  methods: {
    menuSave() {
      this.$store
          .dispatch('menuSave', {
            yazi: this.menuAdi,
            menuTuru: this.menuTuru.key,
            hedef: this.hedef.key,
            link: this.siteLink.link,
            sira: this.siralama,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res);
            (this.siteLink = {
              baslik: 'Ana Sayfa',
              link: './'
            }),
                (this.menuAdi = ''),
                (this.siralama = ''),
                (this.menuTuru = {
                  title: 'Standart Menü',
                  key: 0
                }),
                (this.hedef = {
                  title: 'Aynı Pencere',
                  key: '_parent'
                }),
                res.linkListesi.forEach(element => {
                  this.siteLinks.push({
                    baslik: element.baslik,
                    link: element.link,
                  })
                })
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    menuSil(id) {
      this.$store
          .dispatch('menuSil', {
            id,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    siraUpdate(id) {
      const inputValue = this.$refs[`input${id}`][0].localValue
      this.$store
          .dispatch('menuSiraUpdate', {
            id,
            sira: inputValue,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
.menuList .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.menuList .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  padding: 3px !important;
  color: white !important;
}
</style>
<style scoped>
.bold {
  font-weight: 900 !important;
}

[dir] .table th[data-v-de803358],
[dir] .table td[data-v-de803358] {
  padding: 0.4rem;
}

[dir] .table th[data-v-0a6c94d4],
[dir] .table td[data-v-0a6c94d4] {
  max-width: 160px !important;
}

.row {
  justify-content: end !important;
}

[dir] .table th,
[dir] .table td {
  padding: 0.72rem;
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.card-body {
  padding: 10px 30px;
}

.myHr {
  margin-top: 0 !important;
}

.min-w-50 {
  min-width: 50px !important;
}
</style>
